import { ref, computed, watch } from '@vue/composition-api'
import useTableHelper from '@/helpers/useTableHelper'
import useHttpService from '@/http/useHttpService'
import {useToast} from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default function useRecruitersList() {
    const {recruiters} = useHttpService()
    const toast = useToast()
    let searchTimer = null;


    const {
        refTable,
        perPage,
        totalCount,
        currentPage,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        dataMeta,
        industryIds,
        refetchData
    } = useTableHelper()

    const tableColumns = [
        { key: 'name', sortable: false },
        { key: 'email', sortable: false },
        { key: 'phoneNumber', sortable: false },
        { key: 'industry', sortable: false },
        { key: 'actions', sortable: false },
    ]

    const fetchRecruiters = (ctx, callback) => {
        recruiters.getRecruiters(currentPage.value, perPage.value, searchQuery.value, industryIds.value.toString())
            .then(response => {
                callback(response.results)
                totalCount.value = response.totalCount
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching recruiters list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    watch([currentPage, perPage], () => {
        refetchData()
    })


    watch([searchQuery], () => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            currentPage.value = 1;
            refetchData();
        }, 500);
    })
    watch([industryIds], () => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            currentPage.value = 1;
            refetchData();
        }, 200);
    })

    return {
        refTable,
        perPage,
        totalCount,
        currentPage,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        tableColumns,
        fetchRecruiters,
        dataMeta,
        refetchData,
        industryIds
    }

}
