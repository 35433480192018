<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <div class="d-flex align-items-center justify-content-between">
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1 w-25"
            placeholder="Search..."
          />

          <div class="w-25">
            <v-select
              placeholder="Industry"
              v-model="industryIds"
              :options="industryOptions"
              label="name"
              multiple
              :reduce="(val) => val.uid"
            >
            </v-select>
          </div>
          <div style="display:flex; gap:8px">
            <b-button
              variant="primary"
              @click="openFileUploadPrompt"
              :loading="{ importLoading }"
              >{{ csv ? "" : "Import CSV" }}
            </b-button>
            <input
              type="file"
              id="uploadCsvInput"
              accept=".csv"
              style="display:none;"
              @change="onFileUpload"
            />
            <b-button
              variant="primary"
              @click="$router.push('/external-recruiters/create')"
              >Add new</b-button
            >
          </div>
        </div>
      </div>

      <b-table
        ref="refTable"
        class="position-relative"
        :items="fetchRecruiters"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <p>{{ fetchRecruiters }}</p>

        <template #cell(name)="data">
          {{ data.item.fullName }}
        </template>
        <template #cell(email)="data">
          {{ data.item.email }}
        </template>
        <template #cell(phoneNumber)="data">
          {{ data.item.phoneNumber }}
        </template>
        <template #cell(industry)="data">
          <div
            v-for="(item, index) in data.item.fieldOfWorks"
            :key="item.fieldOfWork"
            class="browser-states"
          >
            <b-badge :variant="`light-primary`" class="text-capitalize">
              {{ item.name }}
            </b-badge>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button size="sm" @click="goToDetails(data.item)">Edit</b-button>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue";
import useRecruitersList from "@/views/SendMyCV/ExternalRecruiters/useRecruiters";
import UsersFilters from "@/components/users/UsersFilters";
import vSelect from "vue-select";
import users from "@/http/users";
import http from "@/http/recruiters";
import { toastMixin } from "@/mixins/toast-mixin";

export default {
  mixins: [toastMixin],
  components: {
    BCard,
    BTable,
    UsersFilters,
    vSelect,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  data() {
    return {
      industryOptions: [],
      csv: null,
      importLoading: false,
    };
  },
  setup() {
    return {
      ...useRecruitersList(),
    };
  },

  methods: {
    goToDetails(item) {
      this.$router.push("/external-recruiters/" + item.uid);
    },
    openFileUploadPrompt() {
      document.getElementById("uploadCsvInput").click();
    },
    async onFileUpload(e) {
      this.importLoading = true;
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      try {
        await http.importRecruiters(formData);
        this.importLoading = false;
        this.refetchData();
        this.showSuccess("Successfully imported CSV file");
      } catch (error) {
        this.importLoading = false;
        this.showError("Error importing CSV file");
      }
    },
  },
  mounted() {
    users.getUsersFilters().then((response) => {
      this.industryOptions = response.fieldOfWorks.filter((industry) => {
        return industry.uid !== "05a0cedc-61f1-4f0d-b01c-9fe48f8aead1";
      });
    });
  },
};
</script>

<style scoped></style>
